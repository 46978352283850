import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';

import AuthNotif from '../../components/AuthNotif/AuthNotif';
import { LOCALSTORAGE_KEYS } from '../../constans/localstorage';
import StorageService from '../../services/storage.service';

import classes from './index.module.scss';

const Index = () => {
  const [userEmail, setUserEmail] = useState('');

  useEffect(() => {
    const resetPwdEmail = StorageService().get(
      LOCALSTORAGE_KEYS.RESET_PWD_EMAIL,
    );
    if (resetPwdEmail) {
      // const {email} = resetPwdEmail;
      setUserEmail(resetPwdEmail);
    }
  }, []);

  return (
    <AuthNotif
      containerClasses={classes.container}
      email={userEmail}
      title="Password reset link has been sent to"
    >
      <Link
        to="/signin"
        className={`${classes.submitBtn} btn btn_md btn-uppercased t-600 btn_block btn btn_common`}
      >
        Okay, thanks
      </Link>
    </AuthNotif>
  );
};

export default Index;
